<template>
  <div class="artist-page-container">
    <div v-if="!loading" class="main-container">
      <div v-if="artistData">
        <el-row>
          <el-col
            :xs="24"
            :sm="24"
            :md="12"
            :lg="12"
            :xl="12"
            class="left-container"
          >
            <div style="margin: 10px 0px 10px 0px">
              <h3 class="title-text">
                {{ artistData.name }}
              </h3>
              <div class="s1-social-links">
                <el-link
                  :href="artistData.twitter_handle"
                  v-if="artistData.twitter_handle"
                  target="_blank"
                  :underline="false"
                >
                  <img src="../assets/icons/twitter-icon.svg" />
                </el-link>
                <el-link
                  :href="artistData.discord_url"
                  v-if="artistData.discord_url"
                  target="_blank"
                  :underline="false"
                >
                  <img src="../assets/icons/discord-icon.svg" />
                </el-link>
                <el-link
                  :href="artistData.instagram_url"
                  v-if="artistData.instagram_url"
                  target="_blank"
                  :underline="false"
                >
                  <img src="../assets/icons/instagram-icon.svg" />
                </el-link>
                <el-link
                  :href="artistData.opensea_url"
                  v-if="artistData.opensea_url"
                  target="_blank"
                  :underline="false"
                >
                  <img src="../assets/icons/opensea-icon.svg" />
                </el-link>
                <el-link
                  :href="artistData.website_url"
                  v-if="artistData.website_url"
                  target="_blank"
                  :underline="false"
                >
                  <img src="../assets/icons/website-icon.svg" />
                </el-link>
              </div>
              <el-card
                style="margin: 5%; width: 90% !important"
                :body-style="{ padding: '10px' }"
              >
                <el-image
                  :src="artistData.image"
                  class="image"
                  fit="contain"
                  style="width: 100%; height: auto; aspect-ratio: 1"
                />
              </el-card>
              <h3 class="from-text" v-if="artistData.from">
                From: {{ artistData.from }}
              </h3>
              <div class="description-text" v-html="artistData.bio"></div>
              <span style="display: flex; align-items: center">
                <a
                  href="/artists"
                  class="all-artists-link"
                  style="display: flex; align-items: center"
                >
                  <el-icon>
                    <ArrowLeft />
                  </el-icon>
                  <span style="margin-left: 10px"> See all artists </span>
                </a>
              </span>
            </div>
          </el-col>
          <el-col
            :xs="24"
            :sm="24"
            :md="12"
            :lg="12"
            :xl="12"
            class="right-container"
          >
            <div
              v-for="(image, index) in artistData.gallery"
              :key="index"
              style="padding: 2%"
              class="grow"
            >
              <el-card
                style="width: 100% !important"
                :body-style="{ padding: '10px' }"
              >
                <el-image
                  :src="image"
                  :preview-src-list="artistData.gallery"
                  :initial-index="index"
                  class="image"
                  fit="contain"
                  style="width: 100%; height: auto; aspect-ratio: 1"
                />
              </el-card>
            </div>
          </el-col>
        </el-row>
      </div>
      <div v-else>
        <el-result
          icon="warning"
          title="404"
          sub-title="We're sorry, we couldn't find that artist anywhere in the Studio."
        >
          <template #extra>
            <el-link href="/" :underline="false">
              <el-button type="primary" size="default">Back</el-button>
            </el-link>
          </template>
        </el-result>
      </div>
    </div>
  </div>
</template>

<script>
  import { useRoute } from 'vue-router'
  import { onBeforeMount, ref } from 'vue'
  import { getArtistBySlug } from '@/services/ArtistService'
  import { ArrowLeft } from '@element-plus/icons'

  export default {
    name: 'Artist',
    components: {
      ArrowLeft,
    },
    setup() {
      const route = useRoute()
      const artistSlug = route.params.artist_slug

      const loading = ref(true)
      const artistData = ref()

      onBeforeMount(async () => {
        loading.value = true
        artistData.value = await getArtistBySlug(artistSlug).then((res) => {
          return res.response
        })
        loading.value = false
      })

      return {
        loading,
        artistData,
      }
    },
  }
</script>
<style scoped>
  .title-text {
    margin-bottom: 5%;
  }
  .description-text {
    margin: 40px 0 20px 0;
    font-size: 1.1rem;
    line-height: 1.5rem;
    text-align: left;
  }
  .artist-page-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .main-container {
    max-width: 1500px;
  }
  .left-container,
  .right-container {
    padding: 1% 5%;
  }
  .s1-social-links img {
    width: 25px;
    height: auto;
    filter: invert(52%) sepia(98%) saturate(1891%) hue-rotate(159deg)
      brightness(94%) contrast(93%);
  }
  .all-artists-link {
    margin-top: 2%;
    color: #409eff !important;
    font-size: 1em;
    font-weight: bolder;
    text-decoration: none;
    text-align: left;
  }
  .s1-social-links a {
    margin: 10px 5px 10px 15px;
  }

  .s1-social-links {
    margin-top: 10px;
  }

  .from-text {
    font-weight: bolder;
    text-align: center;
    font-size: 16px;
    padding: 10px;
  }
  .grow {
    transition: all 0.2s ease-in-out !important;
  }
  .grow:hover {
    transform: scale(1.03) !important;
  }
</style>
